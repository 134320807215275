<template>
  <div>
    <el-card style="margin-bottom: 100px">
      <el-form ref="form" :inline="true">
        <el-form-item class="generate">
          <el-button type="primary" @click="toAdd">添加视频</el-button>
        </el-form-item>
      </el-form>
      <div class="contents">
        <el-table
          :data="alphabetList"
          stripe
          ref="userTable.multipleSelection"
          header-cell-class-name="table-header"
          border
        >
          <template>
            <el-table-column
              label="标题"
              prop="word"
              align="center"
              key="1"
              width="200"
            >
            </el-table-column>
            <el-table-column
              label="观看人数"
              prop="sort"
              align="center"
              key="1"
              width="200"
            >
            </el-table-column>
            <el-table-column label="封面图" align="center" width="300">
              <template slot-scope="scope">
                <el-image
                  class="table-td-thumb"
                  :src="scope.row.pic_file"
                  :preview-src-list="[scope.row.pic_file]"
                  style="width:214px;height:112px"
                ></el-image>
              </template>
            </el-table-column>
            <el-table-column
              label="视频"
              align="center"
              prop="vod_file"
              key="3"
            >
            </el-table-column>
          </template>
          <el-table-column label="操作" align="center" width="200">
            <template slot-scope="scope">
              <el-button
                type="primary"
                icon="el-icon-edit"
                circle
                @click="toEdit(scope.row)"
                size="small"
              ></el-button>

              <el-button
                type="success"
                icon="el-icon-video-play"
                circle
                @click="toRadioPlay(scope.row)"
                size="small"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>

    <!-- 编辑 -->
    <el-dialog
      :visible.sync="editDialog"
      :title="addtitle"
      append-to-body
      width="50%"
      :close-on-click-modal="false"
      @close="editDialogClose"
    >
      <el-form :model="editForm" ref="editForm">
        <template>
          <el-form-item el-form-item label="名称" label-width="100px">
            <el-input
              v-model="editForm.word"
              placeholder="请输入名称"
            ></el-input>
          </el-form-item>
          <el-form-item el-form-item label="观看人数" label-width="100px">
            <el-input
              v-model="editForm.sort"
              placeholder="请输入观看人数"
            ></el-input>
          </el-form-item>
          <el-form-item el-form-item label="视频封面" label-width="100px">
            <el-row>
              <el-upload
                class="avatar-uploader"
                action="123"
                :show-file-list="false"
                accept="image/*"
                :before-upload="beforeThumbUpload"
                style="width:214px;height:112px"
              >
                <img
                  v-if="addImages"
                  :src="addImages"
                  class="avatar"
                  style="width: 100%;height:100%"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-row>
            <el-row>
              <div class="tips">上传封面小不大于10MB</div>
            </el-row>
          </el-form-item>
          <el-form-item el-form-item label="视频讲解" label-width="100px">
            <div v-if="editForm.video_url">
              <video
                :src="editForm.video_url"
                style="width: 300px; height: 200px; cursor: pointer"
                autoplay
                controls
                v-if="editForm.video_url"
                @click="selectVideo"
                ref="videoPlay"
              ></video>
            </div>
            <el-button type="primary" @click="selectVideo"
              >选择视频文件</el-button
            ><span class="file-name">{{ fileVideoName }}</span>
            <el-progress
              :percentage="videoProgress"
              v-if="videoProgress"
            ></el-progress>
            <input
              type="file"
              name="video"
              id=""
              hidden
              ref="video"
              @change="selectVideoFiles"
              accept="video/*"
            />
          </el-form-item>
        </template>
        <el-form-item label-width="40%">
          <el-button type="primary" v-if="isAdd == 'add'" @click="addNow"
            >立即添加</el-button
          >
          <el-button type="primary" v-else-if="isAdd == 'edit'" @click="editNow"
            >立即修改</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 点击视频播放 -->
    <el-dialog
      :visible.sync="videoDialog"
      title="西语放映厅视频"
      append-to-body
      width="50%"
      :close-on-click-modal="false"
      @close="videoDialogClose"
    >
      <div>
        <video
          :src="videoDetailsSrc"
          style="width: 100%; height: 520px; cursor: pointer"
          autoplay
          controls
          ref="videoDetails"
        ></video>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import store from "@/store";
import {
  addLetter,
  updateLetter,
  getLetterList,
  getLetterDetail,
  getReadCosToken
} from "@/api/French/french_alphabet";
import { warningDialog } from "@/utils/dialog";
var COS = require("cos-js-sdk-v5");
import { log } from "util";

export default {
  data() {
    return {
      alphabetList: [],
      editDialog: false,
      videoProgress: 0,
      audioProgress: 0,
      AlpAudioProgress: 0,
      fileVideoName: "",
      isVoiceless: true,
      videoDialog: false,
      videoDetailsSrc: "",
      addtitle: "",
      isAdd: "add",
      editForm: {
        word: "",
        voice: "",
        voice_file: "",
        type: 5,
        mold_type: 4,
        pic_file: "",
        // note: "", //发音要领
        vod_file: "",
        audio_url: "",
        sort: "",
        examples: [
          {
            sentence: "", // 示例单词
            translate: "", // 翻译
            read: "" // 音频文件
          }
        ]
      },
      addImages: null,
      audio_url: "",
      fileAudioName: "",
      fileAlpAudioName: "",
      fileVideoName: ""
    };
  },
  created() {
    this.getAlphabetList({ type: 5, size: 30, mold: 4 });
  },

  methods: {
    beforeThumbUpload(file) {
      const isSize = file.size / 1024 / 1024 < 10;
      if (!isSize) {
        this.$message.error("上传缩略图不能超过 10MB!");
        return;
      }
      let that = this;
      getReadCosToken({ suffix: file.name }).then(res => {
        var cos = new COS({
          getAuthorization: function(options, callback) {
            callback({
              TmpSecretId: res.data.credentials.tmpSecretId,
              TmpSecretKey: res.data.credentials.tmpSecretKey,
              XCosSecurityToken: res.data.credentials.sessionToken,
              StartTime: res.data.startTime,
              ExpiredTime: res.data.expiredTime,
              expiration: res.data.expiration,
              requestId: res.data.requestId
            });
          }
        });
        cos.putObject(
          {
            Bucket: res.data.Bucket,
            Region: res.data.Region,
            Key: res.data.key,
            // ContentType:'multipart/form-data',
            Body: file, // 上传文件对象
            onProgress: function(progressData) {}
          },
          function(err, data) {
            if (data) {
              that.editForm.pic_file = res.data.key;
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = e => {
                that.addImages = e.target["result"]; // 获取文件装换后的Base64位文件
                that.$forceUpdate();
              };
            }
          }
        );
      });
    },
    //添加
    toAdd() {
      this.editDialog = true;
      this.addtitle = "添加视频";
      this.isAdd = "add";
      this.addImages = "";
      this.editForm.word = "";
      this.editForm.sort = "";
      this.editForm.voice = "";
      this.editForm.voice_file = "";
      // this.editForm.note = "";
      this.editForm.vod_file = "";
      this.audioProgress = 0;
      this.videoProgress = 0;
      this.AlpAudioProgress = 0;
      this.fileAlpAudioName = "";
      this.editForm.audio_url = "";
      this.editForm.examples = [
        {
          sentence: "", // 示例单词
          translate: "", // 翻译
          read: "" // 音频文件
        }
      ];
    },
    //立即添加
    addNow() {
      console.log(this.editForm);
      if (this.editForm.word == "") {
        this.$message.error("名称不能为空！");
        return;
      } else if (this.editForm.sort == "") {
        this.$message.error("观看人数不能为空！");
        return;
      } else if (this.editForm.pic_file == "") {
        this.$message.error("封面图不能为空！");
        return;
      } else if (this.editForm.vod_file == "") {
        this.$message.error("视频不能为空！");
        return;
      }
      console.log(this.editForm);
      addLetter(this.editForm).then(res => {
        console.log(res);
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("添加成功!");
        this.editDialog = false;
        this.getAlphabetList({ type: this.editForm.type, size: 30, mold: 4 });
        this.editForm = {
          word: "",
          voice: "",
          voice_file: "",
          type: 5,
          mold_type: 4,
          pic_file: "",
          // note: "", //发音要领
          vod_file: "",
          sort: "",
          examples: [
            {
              sentence: "", // 示例单词
              translate: "", // 翻译
              read: "" // 音频文件
            }
          ]
        };
      });
    },
    //获取列表
    getAlphabetList(type) {
      getLetterList(type).then(res => {
        console.log(res);
        this.alphabetList = res.data;
      });
    },
    // 视频播放弹框关闭事件
    videoDialogClose() {
      if (this.$refs.videoDetails !== undefined) {
        this.$refs.videoDetails.pause();
      }
    },

    // 点击操作栏的视频播放按钮
    toRadioPlay(item) {
      if (item.vod_file == "") {
        this.$message.error("暂无视频,亲请上传视频后重试~");
        return;
      }
      this.videoDetailsSrc = item.vod_file;
      this.videoDialog = true;
    },

    // 点击编辑图标
    toEdit(item) {
      this.addtitle = "编辑视频";
      this.isAdd = "edit";
      this.audioProgress = 0;
      this.videoProgress = 0;
      this.AlpAudioProgress = 0;
      this.fileAlpAudioName = "";
      this.fileAudioName = "";
      this.fileVideoName = "";
      console.log(item);
      getLetterDetail({ id: item.id }).then(res => {
        console.log(res);
        if (res.data.type == 1) {
          this.editForm = res.data;
          this.audio_url = item.voice_file;
          this.editForm.voice_file = this.editForm.voice_file
            .split("?")[0]
            .replace("https://file.qiaxueedu.com/", "");
          // console.log(this.editForm);
        } else if (res.data.type == 5) {
          let data = JSON.parse(res.data.example.replace(/[\\]/g, ""));
          this.editForm.word = res.data.word;
          this.editForm.voice = res.data.voice;
          this.editForm.vod_file = res.data.vod_file
            .split("?")[0]
            .replace("https://file.qiaxueedu.com/", "");
          this.editForm.pic_file = res.data.pic_file
            .split("?")[0]
            .replace("https://file.qiaxueedu.com/", "");
          this.addImages = res.data.pic_file;
          this.editForm.video_url = res.data.vod_file;
          this.editForm.voice_file = res.data.voice_file
            .split("?")[0]
            .replace("https://file.qiaxueedu.com/", "");
          this.audio_url = res.data.voice_file;
          this.editForm.id = res.data.id;
          this.editForm.sort = res.data.sort;
          console.log(this.editForm.id);
          this.editForm.type = res.data.type;
          this.editForm.examples = data;
          this.editForm.audio_url = data[0].read;
          // console.log(this.editForm);
          // console.log(this.audio_url);
          this.editForm.examples[0].read = this.editForm.examples[0].read
            .split("?")[0]
            .replace("https://file.qiaxueedu.com/", "");
          // console.log(this.editForm.audio_url);
          this.editForm.examples[0].translate = JSON.parse(
            res.data.example
          )[0].translate;
          this.editForm.examples[0].translate = unescape(
            this.editForm.examples[0].translate.replace(/u/g, "%u")
          );
          this.editForm.examples[0].sentence = JSON.parse(
            res.data.example
          )[0].sentence;
          this.editForm.examples[0].sentence = unescape(
            this.editForm.examples[0].sentence.replace(/u/g, "%u")
          );
          this.editForm.examples[0].sentence = unescape(
            this.editForm.examples[0].sentence.replace(/%/g, "")
          );
        }
      });
      this.editDialog = true;
    },

    // 点击立即修改
    editNow() {
      console.log(this.editForm);
      if (this.editForm.word == "") {
        this.$message.error("名称不能为空！");
        return;
      } else if (this.editForm.pic_file == "") {
        this.$message.error("封面图不能为空！");
        return;
      } else if (this.editForm.vod_file == "") {
        this.$message.error("视频不能为空！");
        return;
      }
      console.log(this.editForm);
      updateLetter(this.editForm).then(res => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          // this.progress = 0;
          // this.editForm.vod_file = "";
          return;
        }
        this.$message.success("修改成功!");
        this.editDialog = false;
        // this.progress = 0;
        this.editForm.vod_file = "";
        this.editForm.pic_file = "";
        this.getAlphabetList({ type: this.editForm.type, size: 30, mold: 4 });
      });
    },

    // 修改弹窗关闭触发
    editDialogClose() {
      if (this.$refs.videoPlay !== undefined) {
        this.$refs.videoPlay.pause();
      }
      this.fileAudioName = "";
      this.fileVideoName = "";
      this.audio_url = "";
      this.editForm.video_url = "";
    },

    // 点击选择视频文件按钮
    selectVideo() {
      this.$refs.video.click();
    },

    // 选择了视频文件
    selectVideoFiles() {
      let file = this.$refs.video.files[0];
      if (this.$refs.video.files.length == 0) {
        this.editForm.vod_file = "";
        return;
      }
      this.editForm.vod_file = file.name;
      this.fileVideoName = file.name;
      // if (file.size / 1024 / 1024 > 30) {
      //   this.$message.error("音频不能大于 30M ");
      //   return;
      // }
      getReadCosToken({ suffix: file.name }).then(res => {
        this.CosObj = res.data;
        var that = this;
        var cos = new COS({
          getAuthorization: function(options, callback) {
            callback({
              TmpSecretId: res.data.credentials.tmpSecretId,
              TmpSecretKey: res.data.credentials.tmpSecretKey,
              XCosSecurityToken: res.data.credentials.sessionToken,
              StartTime: res.data.startTime,
              ExpiredTime: res.data.expiredTime,
              expiration: res.data.expiration,
              requestId: res.data.requestId
            });
          }
        });
        cos.putObject(
          {
            Bucket: that.CosObj.Bucket,
            Region: that.CosObj.Region,
            Key: that.CosObj.key,
            // ContentType:'multipart/form-data',
            Body: file, // 上传文件对象
            onProgress: function(progressData) {
              that.videoProgress = progressData.percent * 100;
              that.editForm.vod_file = that.CosObj.key;
              that.editForm.video_url = "";
            }
          },
          function(err, data) {
            console.log(err || data);
          }
        );
      });
    }
  }
};
</script>

<style scoped lang="less">
.avatar {
  z-index: 999;
}

* {
  box-sizing: border-box;
}
.generate {
  float: right;
}
li {
  list-style: none;
  cursor: pointer;
}
.navs {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #323232;
  padding: 0 20px;
  .headers {
    border-bottom: 1px solid #eeeeee;
    height: 120px;
    line-height: 50px;
    width: 100%;
    height: 50px;
    ul {
      width: 100%;
      li {
        height: 50px;
        float: left;
        margin-right: 60px;
      }
    }
    .selected {
      color: #38a28a;
      border-bottom: 1px solid #38a28a;
    }
  }
}
.contents {
  margin-top: 10px;
}
/deep/ .el-upload--text {
  background-color: #fff;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  box-sizing: border-box;
  width: 214px;
  height: 112px;
  text-align: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 214px;
  height: 112px;
  line-height: 112px;
  text-align: center;
}
</style>
